import { template as template_d173dc5ad630450090be83069fd38cbb } from "@ember/template-compiler";
const SidebarSectionMessage = template_d173dc5ad630450090be83069fd38cbb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
